import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'

// CSS
import 'styles/PrivacyDeclaration.scss'

// Components
import Layout from 'components/Layout'
import SEO from 'components/SEO'
import Content from 'components/Content'
import { BannerDefault } from 'components/Elements'


const PrivacyDeclaration = () => {
  const {
    privacy: {
      acf: {
        banner_image: {
          localFile: {
            childImageSharp: {
              fluid: bannerImage
            }
          }
        },
        banner_text: bannerText,
        content,
      },
      yoast_meta: yoast
    }
  } = useStaticQuery(graphql`{
    privacy: wordpressPage(wordpress_id: {eq: 516}) {
      title
      acf {
        banner_image {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 2000) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        banner_text
        content
      }
      yoast_meta {
        name
        content
        property
      }
    }
  }`)

  return (
    <Layout subFooter>
      <SEO yoast={{ meta: yoast }} />
      <BackgroundImage className="privacy-image" fluid={bannerImage} />
      <BannerDefault className="color-background-secondary py-4">
        <h2 className="w-100 text-center color-text-light font-size-xl font-weight-xl">{bannerText}</h2>
      </BannerDefault>
      <Content className="container py-5" content={content} />
    </Layout>
  )
}

export default PrivacyDeclaration